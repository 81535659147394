import React, { useContext } from 'react';

import { MessageBox } from '@xchange/uikit';
import { HeaderOnlyLayout } from 'components';
import DocumentCreatorForm from './DocumentCreatorForm';
import Context, { DocumentCreatorWrapper } from './context';

const DocumentCreator = () => {
  const { fields, generateDocument, error, success } = useContext(Context);

  const submit = values => {
    const formValues = {};
    Object.keys(values).forEach(key => {
      formValues[key] = Array.isArray(values[key]) ? values[key].join(';') : values[key];
    });
    generateDocument(formValues);
  };

  const getContent = () => {
    if (error) return (
      <MessageBox type="error">
        {error.message} <br />
        This link is expired or broken.
      </MessageBox>
    );
    if (success) return (
      <MessageBox type="success">
        Thank you. <br /> An email will follow shortly with a request for you to sign this document. <br /> You can close this window.
      </MessageBox>
    );
    return fields ? (
      <>
        <h3>Create Document</h3>
        <DocumentCreatorForm
          fields={fields}
          onSubmit={submit}
        />
      </>
    ) : null;
  };

  return (
    <HeaderOnlyLayout>
      <div className="create-document">
        {getContent()}
      </div>
    </HeaderOnlyLayout>
  );
};

export default props => (
  <DocumentCreatorWrapper>
    <DocumentCreator {...props} />
  </DocumentCreatorWrapper>
);