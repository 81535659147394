import React from 'react';
import styled from '@emotion/styled';
import { Checkbox, RadioButton, Input, InputProps } from '@xchange/uikit';

interface FormFieldProps extends InputProps {
  field: DocumentFormField;
  defaultValue?: string | number;
  error?: Boolean;
  inputProps?: any;
};

const FormField: React.FC<FormFieldProps> = ({
  field: {
    type,
    name,
    question,
    values,
    choices
  },
  defaultValue,
  error,
  inputProps
}) => {
  const getComponent = (value, idx) => {
    switch (type) {
      case 'radio':
        return (
          <RadioButton
            key={`${name}_${value}`}
            value={value}
            label={choices[idx]}
            defaultChecked={defaultValue === value}
            {...inputProps}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            key={`${name}_${value}`}
            value={value}
            label={choices[idx]}
            defaultChecked={defaultValue === value}
            {...inputProps}
          />
        );
      case 'text':
        return (
          <Input
            key={`${name}`}
            defaultValue={defaultValue}
            maxLength={100}
            {...inputProps}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StyledInputGroup>
      <p className={type}>{question}</p>
      {values.map(getComponent)}
    </StyledInputGroup>
  );
};

export default FormField;

const StyledInputGroup = styled.div`
  &.error {
    color: ${props => props.theme.colors.red};
    padding: 0 4px 4px;
    border: 1px solid ${props => props.theme.colors.red};
    border-radius: ${props => props.theme.misc.borderRadius};
  }
  .checkbox + .checkbox {
    margin-left: 10px;
  }
  .input {
    width: 100%;
  }
  p.label {
    margin: 1.5em 0 0;
    font-weight: bold;
  }
`;