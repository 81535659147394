import React, { createContext, useState, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { getDocumentForm, generateExternalDocumentData } from 'api/documents';

interface DocumentCreatorContextValues {
  fields: DocumentFormField[];
  error: Error | null;
  success: boolean;
  generateDocument: (value: Object) => void;
}

const DocumentCreatorContext = createContext({} as DocumentCreatorContextValues);

export const DocumentCreatorWrapper = ({ children }) => {
  const [viewId] = useQueryParam('view', StringParam);
  const [fields, setFields] = useState<DocumentFormField[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchDocumentForm = async (viewId) => {
    try {
      const fields = await getDocumentForm(viewId);
      setFields(fields);
    } catch (err) {
      setError(err);
    }
  };

  const generateDocument = async (values: Object) => {
    if (!viewId) return;
    try {
      await generateExternalDocumentData(values, viewId);
      setSuccess(true);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (viewId) fetchDocumentForm(viewId);
  }, [viewId]);

  const contextValues = {
    fields,
    success,
    error,
    generateDocument
  };
  return (
    <DocumentCreatorContext.Provider value={contextValues}>
      {children}
    </DocumentCreatorContext.Provider>
  );
};

export default DocumentCreatorContext;